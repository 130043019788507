<template>
  <div class="user-bio">
    <div class="bio-items">
      <h2>Add Details</h2>
      <div class="bio-image">

        <div class="dp-uploader-wrap">
          <div v-show="showPhotoOption" class="dp-uploader-options">
            <button type="text" class="dp-up-item" name="">Update</button>
            <button type="text" class="dp-up-item" name="">Delete</button>
          </div>
          <div class="dp-uploader" v-on:click="togglePhotoOption()">
            <img :src="currentUser.photo_url" alt="menu" />
          </div>

        </div>

        <div class="optional-text">(optional)</div>
      </div>
      <div class="bio-text-wrap">
        <div class="bio-meta">Bio</div>
        <div class="bio-text-editable" contenteditable="true" id="bio_title_text">{{ folio.title }}</div>
      </div>
      <div class="bio-subtitle-wrap">
        <div class="bio-meta">Sub Title</div>
        <div class="bio-subtitle-editable" id="bio_subtitle_text" contenteditable="true">{{ folio.subtitle }}</div>
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" class="btn-cancel">Cancel</button>
      <button type="button" class="btn-advance" v-on:click="updateFolio()">
        Looks Good. Proceed
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "UserBio",
  data() {
    return {
      folio: {
        created_by: {
          photo_url: ''
        },
        title: "Hey, welcome to to my Glims profile.",
        subtitle: "",
        description: "Watch showreel of my top works.",
      },
      showPhotoOption: false,
    };
  },
  created() {
    this.getFolio();
  },
  computed: {
    currentUser() {
      return this.$store.state.globalUserData;
    },
  },
  methods: {
    getFolio() {
      let context = this;
      var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
      url.pathname += "/get_folio/";
      context.showLoader();
      axios
        .get(url)
        .then(function (response) {
          context.hideLoader();
          if (!context.genericResponseHandling(response)) {
            return;
          }
          let data = response.data;
          context.folio = data.folio;
        })
        .catch(this.genericErrorHandling);
    },
    updateFolio() {
      let context = this;
      var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
      url.pathname += "/update_folio/";
      url.searchParams.append("title", bio_title_text.textContent);
      url.searchParams.append("subtitle", bio_subtitle_text.textContent)
      context.showLoader();
      axios
        .get(url)
        .then(function (response) {
          context.hideLoader();
          if (!context.genericResponseHandling(response)) {
            return;
          }
        })
        .catch(this.genericErrorHandling);
    },
    togglePhotoOption() {
      this.showPhotoOption = this.showPhotoOption === false ? true : false;
    },
  },
};
</script>
<style scoped lang="scss">
.dp-uploader img {
  border-radius: 50%;
}

.user-bio {
  text-align: center;
  width: 100%;
}

.bio-items {
  flex-grow: 1;
}

.bio-meta {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: #fff;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.bio-text-editable {
  font-family: "Satoshi", sans-serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.2;
  color: #fff;
  width: 470px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  border: 0;
  outline: 0;
  min-height: 78px;

  @media (min-width: 768px) {
    font-size: 50px;
    min-height: 122px;
  }

  position: relative;
  display: block;
  border: 0;
  outline: 0;
  caret-color: transparent;

  &:empty:after {
    content: "Hey, welcome to to my Glims profile.";
    color: #8a8a8e;
  }

  &:empty::before {
    content: "|";
    color: #fff;
    position: relative;
  }

  &:active:empty::before,
  &:focus:empty::before {
    animation: toggleOpacity 1s infinite;
  }

  &:not(:empty)::after {
    content: "|";
    color: #ff0;
    position: relative;
  }

  &:focus:not(:empty)::after {
    animation: toggleOpacity 1s infinite;
  }
}

@keyframes toggleOpacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bio-subtitle-editable {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;

  @media (min-width: 768px) {
    font-size: 20px;
  }

  position: relative;
  display: block;
  border: 0;
  outline: 0;
  caret-color: transparent;

  &:empty:after {
    content: "Watch showreel of my top works.";
    color: #8a8a8e;
  }

  &:empty::before {
    content: "|";
    color: #fff;
    position: relative;
  }

  &:active:empty::before,
  &:focus:empty::before {
    animation: toggleOpacity 1s infinite;
  }

  &:not(:empty)::after {
    content: "|";
    color: #ff0;
    position: relative;
  }

  &:focus:not(:empty)::after {
    animation: toggleOpacity 1s infinite;
  }
}

.bio-image {
  padding: 20px 0;

  .dp-uploader-wrap {
    position: relative;
  }

  .dp-uploader-options {
    position: absolute;
    bottom: calc(100% + 15px);
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #393939;
    border-radius: 5px;
    width: 80px;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-right: 10px solid transparent;
      border-top: 10px solid #393939;
      border-left: 10px solid transparent;
      z-index: 2;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .dp-up-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.15;
      color: #ffffff;
      border: 0;
      border-bottom: 1px solid #565656;
      padding: 8px 16px;
      cursor: pointer;
      outline: 0;
      margin: 0;
      background: transparent;

      &:hover {
        color: #f0ff64;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .optional-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: #878787;
    margin-top: 10px;
  }
}

.bio-text-wrap {
  padding: 20px 0;
}

.bio-subtitle-wrap {
  padding: 20px 0;
}

.btn-wrap {
  margin-top: 40px;
  padding: 10px 0;
  text-align: right;

  button {
    background: var(--primary-cta-background);
    padding: 15px 30px;
    color: var(--primary-cta-color);
    border-radius: 5px;
    border: 0;
    outline: 0;
    font-size: 16px;
    cursor: pointer;

    &.btn-cancel {
      background: #8d8d8d;
      margin-right: 24px;
      color: #fff;

      &:hover {
        background: #676767;
      }
    }

    &.btn-advance {
      background: #f0ff64;

      &:hover {
        background: #ff0;
      }
    }
  }
}
</style>
