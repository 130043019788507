<template>
  <HeaderSection />
  <main class="page-content">
    <UserBio />
  </main>
</template>

<script>
import UserBio from "../components/NewUser/UserBio.vue";
import HeaderSection from "../components/Header/HeaderSection.vue";
export default {
  name: "Bio",
  components: {
    UserBio,
    HeaderSection,
  },
};
</script>
